import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Pagination, Controller, Autoplay } from 'swiper/modules';
import { isPhone } from "../util/DeviceType"


import '../styles/slick-gallery.scss';



export default function SlickGallery({ whatToShow, images }) {

    const swiper1 = useRef(null);
    const swiper2 = useRef(null);
    const swiper3 = useRef(null);
    const swiper4 = useRef(null);
    const swiper5 = useRef(null);

    const [sliderDirection, setSliderDirection] = useState('horizontal');
    const [slidesPerView, setSlidesPerView] = useState(2);


    useEffect(() => {
        if (isPhone()) {
            // setSliderDirection('vertical');
            setSlidesPerView(1);
        }
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////
    // AWARDS
    const importAll = (r) => r.keys().map(r);
    // const imagesAw = importAll(require.context('../assets/awards/best', false, /\.(webp)$/));

    // imagesAw.sort((a, b) => {
    //     // Extract file names without extensions
    //     const nameA = a.split('/').pop().split('.')[0];
    //     const nameB = b.split('/').pop().split('.')[0];

    //     // Compare file names
    //     return nameA.localeCompare(nameB);
    // });

    const renderSlidesAwards = () => {
        return images.map((image, index) => (
            <SwiperSlide key={index}>
                <div className='inverted-image-cont' >
                    <img className='inverted-image' src={image} alt={`Slide ${index + 1}`} />
                </div>
            </SwiperSlide>
        ));
    };
    const awardsTitles =
        [
            'WINNER',
            'WINNER',
            'WINNER',
            'WINNER',
            'WINNER',
            'WINNER',
            'HONORABLE Mention',
            'HONORABLE Mention',
            'HONORABLE Mention',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION',
            'SELECTION'
        ];
    const renderTitlesAwards = () => {
        let buttonsToRender = awardsTitles;
        if (!isPhone()) {
            buttonsToRender = awardsTitles.filter((title, index) => index < awardsTitles.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <h2 data-translation-key={`awardsTitles${index}`} className="font-h2-title">{title}</h2>
            </SwiperSlide>
        ));
    };
    const awardsHeadings =
        [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20'
        ];
    const renderHeadingsAwards = () => {
        let buttonsToRender = awardsHeadings;
        if (!isPhone()) {
            buttonsToRender = awardsHeadings.filter((title, index) => index < awardsHeadings.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <h1 className="font-h1">{title}</h1>
            </SwiperSlide>
        ));
    };
    const awardsButtons =
        [
            'BEST COMEDY SHORT FILM - HOLLYWOOD INDEPENDENT FILM FESTIVAL - 2023',
            'BEST INTERNATIONAL STUDENT SHORT FILM - VYRE Film Festival 2023 - USA 2022',
            'BEST STUDENT SHORT FILM - BIMIFF - Brazil International Monthly Independent Film Festival - 2023',
            'BEST STUDENT SHORT FILM - Cuckoo International Film Awards - India 2022',
            'BEST STUDENT SHORT FILM - Eastern Europe International Movie Awards - Turkey 2022',
            'BEST STUDENT SHORT FILM - POOMBUKAR INDEPENDENT FILM FESTIVAL - India 2022',
            'HONORABLE MENTION - 1st Monthly FIlm Festival - 2022',
            'Honorable Mention - Athens International Monthly Art Film Festival - 2023',
            'NOMINEE - Play Short - International Film Awards - 2023',
            'OFFICIAL SELECTION - 8  Halfilm Awards - 2022',
            'OFFICIAL SELECTION - Bridge Fest - 2022',
            'OFFICIAL SELECTION - Ealing Film Festival - 2022',
            'OFFICIAL SELECTION - European Short Awards - 2022',
            'OFFICIAL SELECTION - Golden Bridge stanbul Short Film Festival - 2022',
            'OFFICIAL SELECTION - Iconic Images Film Festival - 2022',
            'OFFICIAL SELECTION - Ip Amarelo Film Festival - 2022',
            'OFFICIAL SELECTION - Kosice International Film Festival - 2022',
            'OFFICIAL SELECTION - MosFilmFest - 2022',
            'OFFICIAL SELECTION - Swiss International Film Festival - 2023',
            'OFFICIAL SELECTION - World Indie Film Awards - 2023'
        ];
    const renderButtonsAwards = () => {
        let buttonsToRender = awardsButtons;
        if (!isPhone()) {
            buttonsToRender = awardsButtons.filter((title, index) => index < awardsButtons.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <p className="font-h3-buttons-awards">{title}</p>
            </SwiperSlide>
        ));
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////

    const renderButtonsPagination = () => {
        let buttonsToRender = awardsButtons;
        if (!isPhone()) {
            buttonsToRender = awardsButtons.filter((title, index) => index < awardsButtons.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <p className="font-h3-buttons-awards hidden">{title}</p>
            </SwiperSlide>
        ));
    };
    //LAZAR
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // MOVIE SLOZNA BRACA
    // const imagesSloznaBraca = importAll(require.context('../assets/movies/sloznabraca', false, /\.(webp)$/));
    const renderSlidesSloznaBraca = () => {
        return images.map((image, index) => (
            <SwiperSlide key={index}>
                <img src={image} alt={`Slide ${index + 1}`} />
            </SwiperSlide>
        ));
    };
    const sloznaBracaTitles =
        [
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća',
            'Složna Braća'
        ];
    const renderTitlesSloznaBraca = () => {
        let buttonsToRender = sloznaBracaTitles;
        if (!isPhone()) {
            buttonsToRender = sloznaBracaTitles.filter((title, index) => index < sloznaBracaTitles.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <h2 className="font-h2-title-slozna">{title}</h2>
            </SwiperSlide>
        ));
    };
    const sloznaBracaHeadings =
        [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22'
        ];
    const renderHeadingsSloznaBraca = () => {
        let buttonsToRender = sloznaBracaHeadings;
        if (!isPhone()) {
            buttonsToRender = sloznaBracaHeadings.filter((title, index) => index < sloznaBracaHeadings.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <h1 className="font-h1">{title}</h1>
            </SwiperSlide>
        ));
    };
    const sloznaBracaButtons =
        [
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes'
        ];
    const renderButtonsSloznaBraca = () => {
        let buttonsToRender = sloznaBracaButtons;
        if (!isPhone()) {
            buttonsToRender = sloznaBracaButtons.filter((title, index) => index < sloznaBracaButtons.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <p data-translation-key={`behindTheScenes${index}`} className="font-h3-buttons">{title}</p>
            </SwiperSlide>
        ));
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////

    const renderButtonsSloznaPagination = () => {
        let buttonsToRender = sloznaBracaButtons;
        if (!isPhone()) {
            buttonsToRender = sloznaBracaButtons.filter((title, index) => index < sloznaBracaButtons.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <p className="font-h3-buttons hidden">{title}</p>
            </SwiperSlide>
        ));
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // OTHER MOVIES
    // const imagesOtherMovies = importAll(require.context('../assets/movies/others', false, /\.(webp)$/));
    const renderSlidesMovies = () => {
        return images.map((image, index) => (
            <SwiperSlide key={index}>
                <img src={image} alt={`Slide ${index + 1}`} />
            </SwiperSlide>
        ));
    };
    const otherMoviesTitles =
        [
            'Berlin Rising',
            'Berlin Rising',
            'Berlin Rising',
            'Berlin Rising',
            'Berlin Rising',
            'Berlin Rising',
            'Berlin Rising',
            'Berlin Rising',
            'Berlin Rising',
            'True story of real life crash',
            'True story of real life crash',
            'True story of real life crash',
            'Znanje imanje',
            'Znanje imanje',
        ];
    const renderTitlesMovies = () => {
        let buttonsToRender = otherMoviesTitles;
        if (!isPhone()) {
            buttonsToRender = otherMoviesTitles.filter((title, index) => index < otherMoviesTitles.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <div className='leading-10'>
                    <h2 className="font-h2-title-other-movies">{title}</h2>
                </div>
            </SwiperSlide>
        ));
    };
    const otherMoviesHeadings =
        [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
        ];
    const renderHeadingsMovies = () => {
        let buttonsToRender = otherMoviesHeadings;
        if (!isPhone()) {
            buttonsToRender = otherMoviesHeadings.filter((title, index) => index < otherMoviesHeadings.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <h1 className="font-h1">{title}</h1>
            </SwiperSlide>
        ));
    };
    const otherMoviesButtons =
        [
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
            'Behind the scenes',
        ];
    const renderButtonsMovies = () => {
        let buttonsToRender = otherMoviesButtons;
        if (!isPhone()) {
            buttonsToRender = otherMoviesButtons.filter((title, index) => index < otherMoviesButtons.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <p data-translation-key={`behindTheScenesO${index}`} className="font-h3-buttons">{title}</p>
            </SwiperSlide>
        ));
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////

    const renderSliderButtonsPag = () => {
        let buttonsToRender = otherMoviesButtons;
        if (!isPhone()) {
            buttonsToRender = otherMoviesButtons.filter((title, index) => index < otherMoviesButtons.length - 1);
        }
        return buttonsToRender.map((title, index) => (
            <SwiperSlide key={index}>
                <p className="font-h3-buttons hidden">{title}</p>
            </SwiperSlide>
        ));
    };


    // TOTAL
    const renderSliderImages = () => {
        if (whatToShow === 'awards') {
            return renderSlidesAwards();
        } else if (whatToShow === 'movieSloznaBraca') {
            return renderSlidesSloznaBraca();
        } else if (whatToShow === 'othersMovies') {
            return renderSlidesMovies();
        } else {
            return <p>Default content for other values</p>;
        }
    };
    const renderSliderTitles = () => {
        if (whatToShow === 'awards') {
            return renderTitlesAwards();
        } else if (whatToShow === 'movieSloznaBraca') {
            return renderTitlesSloznaBraca();
        } else if (whatToShow === 'othersMovies') {
            return renderTitlesMovies();
        } else {
            return <p>Default content for other values</p>;
        }
    };
    const renderSliderHeadings = () => {
        if (whatToShow === 'awards') {
            return renderHeadingsAwards();
        } else if (whatToShow === 'movieSloznaBraca') {
            return renderHeadingsSloznaBraca();
        } else if (whatToShow === 'othersMovies') {
            return renderHeadingsMovies();
        } else {
            return <p>Default content for other values</p>;
        }
    };
    const renderSliderButtons = () => {
        if (whatToShow === 'awards') {
            return renderButtonsAwards();
        } else if (whatToShow === 'movieSloznaBraca') {
            return renderButtonsSloznaBraca();
        } else if (whatToShow === 'othersMovies') {
            return renderButtonsMovies();
        } else {
            return <p>Default content for other values</p>;
        }
    };

    const renderSliderPagination = () => {
        if (whatToShow === 'awards') {
            return renderButtonsPagination();
        } else if (whatToShow === 'movieSloznaBraca') {
            return renderButtonsSloznaPagination();
        } else if (whatToShow === 'othersMovies') {
            return renderSliderButtonsPag();
        } else {
            return <p>Default content for other values</p>;
        }
    };


    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="swiper-pagination"><span class="custom-bullet ' + className + '">' + (0) + '</span></<div';
        },
    };

    const handleSwiper1 = (swiper) => {
        swiper1.current = swiper;
        if (swiper2.current) {
            swiper2.current.controller.control = swiper1.current;
        }
    };

    const handleSwiper2 = (swiper) => {
        swiper2.current = swiper;
        if (swiper1.current) {
            swiper1.current.controller.control = swiper2.current;
        }
    };

    const handleSwiper3 = (swiper) => {
        swiper3.current = swiper;
        if (swiper2.current) {
            swiper2.current.controller.control = [swiper3.current, swiper1.current];
        }
    };

    const handleSwiper4 = (swiper) => {
        swiper4.current = swiper;
        if (swiper3.current) {
            swiper3.current.controller.control = swiper4.current;
        }
    };

    const handleSwiper5 = (swiper) => {
        swiper5.current = swiper;
        if (swiper4.current) {
            swiper4.current.controller.control = swiper5.current;
        }

    };
    /*
        useEffect(() => {
            let interactionTimeout;
    
            const handleSwiperInteraction = () => {
                // User interacted with Swiper, clear the timeout
                clearTimeout(interactionTimeout);
                // Disable autoplay
                if (swiper1.current) {
                    swiper1.current.autoplay.stop();
                }
            };
    
            const handleNoInteraction = () => {
                console.log("autoplay")
                if (swiper1.current) {
                    swiper1.current.autoplay.start();
                }
            };
    
            if (swiper1.current) {
                swiper1.current.on('touchStart', handleSwiperInteraction);
                swiper1.current.on('slideChange', handleSwiperInteraction);
    
                interactionTimeout = setTimeout(handleNoInteraction, 1000);
            }
    
            return () => {
                if (swiper1.current) {
                    swiper1.current.off('touchStart', handleSwiperInteraction);
                    swiper1.current.off('slideChange', handleSwiperInteraction);
                }
                clearTimeout(interactionTimeout);
            };
        }, []);
    */
    return (
        <div >
            <div className='swiper-slide-container'>
                <Swiper
                    direction={sliderDirection}
                    autoplay={{ delay: 4000, disableOnInteraction: true }}
                    slidesPerView={slidesPerView}
                    centeredSlides={isPhone() == true ? true : false}
                    spaceBetween={isPhone() == true ? 30 : (whatToShow == 'awards') ? 0 : 150}
                    grabCursor={true}
                    modules={[Controller, Autoplay]}
                    className="mySwiper"
                    onSwiper={handleSwiper1}
                >
                    {renderSliderImages()}
                </Swiper>

            </div>
            <div className='swiper-slide-container-pagination'>
                <Swiper
                    direction="horizontal"
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={0}
                    grabCursor={false}
                    pagination={pagination}
                    modules={[Controller, Pagination]}
                    className="mySwiper"
                    onSwiper={handleSwiper2}
                >
                    {renderSliderPagination()}
                </Swiper>
            </div>

            <div className='swiper-slide-container-titles'>

                <Swiper
                    direction="vertical"
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={0}
                    grabCursor={false}
                    modules={[Controller]}
                    className="mySwiper"
                    onSwiper={handleSwiper3}
                >
                    {renderSliderTitles()}
                </Swiper>
            </div>


            <div className='swiper-slide-container-buttons'>

                <div className='scroll-icon-container-gallery'>
                    <a className="scroll-icon scroll-icon-container-flip"></a>
                </div>
                <Swiper
                    direction="vertical"
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={0}
                    grabCursor={false}
                    modules={[Controller]}
                    className="mySwiper"
                    onSwiper={handleSwiper4}
                >
                    {renderSliderButtons()}
                </Swiper>
            </div>

            <div className='swiper-slide-container-num'>
                <Swiper
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={0}
                    grabCursor={false}
                    modules={[Controller]}
                    className="mySwiper"
                    onSwiper={handleSwiper5}
                >
                    {renderSliderHeadings()}
                </Swiper>
            </div>



        </div >
    );
}
