import React, { useState, useEffect } from 'react';
import '../styles/panel-intro.scss';
import Panel from "./Panel";
import Countdown from "./Countdown";
import NatinaZavesa from "./NatinaZavesa";
import { isPhone } from '../util/DeviceType';

const importAll = (r) => r.keys().map(r);
const imagesAw = importAll(require.context('../assets/newawards/', false, /\.(png)$/));
const imagesBr = importAll(require.context('../assets/movies/sloznabraca', false, /\.(png)$/));
const imagesOt = importAll(require.context('../assets/movies/others', false, /\.(png)$/));
// const imagesAw = importAll(require.context('../assets/awardspng/', false, /\.(png)$/));
// const imagesBr = importAll(require.context('../assets/awardspng/', false, /\.(png)$/));
// const imagesOt = importAll(require.context('../assets/awardspng/', false, /\.(png)$/));
var intervalId;


const PanelIntro = () => {
    // You can use state to manage the loaded images
    const [loadedImagesAwards, setLoadedImagesAwards] = useState([]);
    const [loadedImagesBraca, setLoadedImagesBraca] = useState([]);
    const [loadedImagesOthers, setLoadedImagesOthers] = useState([]);
    const [isTouching, setTouching] = useState(false);

    const handleTouchStart = () => {
        setTouching(true);
    };

    const handleTouchEnd = () => {
        setTouching(false);
    };

    useEffect(() => {
        // Load the images once when the component mounts
        setLoadedImagesAwards(imagesAw);
        setLoadedImagesBraca(imagesBr);
        setLoadedImagesOthers(imagesOt);
    }, []);


    const options = {
        imgSrc1: '#acc',
        imgSrc2: '#fff',
        containerName: "placeholder-natina-zavesa",
        columns: 36,
        margin: 0
    };


    const [showPanel, setShowPanel] = useState(false);


    // const addStyles = () => {

    //     const elements = document.querySelectorAll('.btn, .btn-info, .btn-h1, .btn-author, .btn-line');

    //     document.querySelector('.countdown').style.opacity = '0';

    //     elements.forEach(element => {

    //         if (element.classList.contains('btn')) {
    //             element.classList.add('btn-clickanim')
    //         }

    //         if (element.classList.contains('btn-info')) {
    //             element.classList.add('btn-info-clickanim')
    //         }

    //         if (element.classList.contains('btn-h1')) {
    //             element.classList.add('btn-h1-clickanim')
    //         }

    //         if (element.classList.contains('btn-author')) {
    //             element.classList.add('btn-author-clickanim')
    //         }

    //         if (element.classList.contains('btn-line')) {
    //             element.classList.add('btn-line-clickanim')
    //         }

    //     });
    // };

    const handleStartIntro = (state = true) => {
        if (intervalId) clearInterval(intervalId);

        if (document.querySelector('.countdown')) {
            const element = document.querySelector('.countdown');
            element.style.transition = 'opacity 0.5s'; // Adjust the duration as needed
            element.style.opacity = '0';
        }
        if (document.querySelector('.scene-hole'))
            document.querySelector('.scene-hole').style.display = 'flex';
        if (document.querySelector('.arrow-intro-container'))
            document.querySelector('.arrow-intro-container').style.opacity = '0';
        setTimeout(() => {
            if (document.querySelector('.btn-info')) {
                document.querySelector('.btn-info').classList.add('btn_white');
                document.querySelector('.btn-info').className += ' btn_white';
            }
            if (document.querySelector('.btn')) {
                // document.querySelector('.btn').classList.add('btn_shrink');
                document.querySelector('.btn').className += ' btn_shrink';
            }

            setTimeout(() => {
                if (document.querySelector('.scene-button'))
                    document.querySelector('.scene-button').style.display = 'none';


                setTimeout(() => {
                    setShowPanel(state);
                    const natinaZavesa = document.querySelector('.placeholder-natina-zavesa');
                    if (natinaZavesa)
                        natinaZavesa.style.display = 'none';
                    setTimeout(() => {
                        if (document.querySelector('.site-wrap')) {
                            const element = document.querySelector('.site-wrap');
                            element.style.opacity = '1';
                        }
                    }, 100);
                }, 1000);
            }, 1000);
        }, 200);
    };

    const [counter, setCounter] = useState(3);
    useEffect(() => {
        intervalId = setInterval(() => {
            setCounter(prevCounter => {
                const newCounter = prevCounter - 1;
                if (newCounter == 1) {
                    handleStartIntro(true);
                }
                if (newCounter <= 0) {
                    document.querySelector('.countdown').style.opacity = '0';
                    clearInterval(intervalId);
                }
                return newCounter < 0 ? 0 : newCounter;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Countdown triggerIntro={handleStartIntro} />
            {!showPanel && <>
                <div
                    // onClick={handleStartIntro} 
                    className='scene-button'>
                    <div className={`btn ${isTouching ? 'btn-touch' : ''}`}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}>
                        <div className="number-new">{counter}</div>
                    </div>
                </div>

                <div className='scene-hole'>
                    <div className='hol-hole'>
                        {[...Array(8)].map((_, index) => (
                            <i key={index} className='hol-a'></i>
                        ))}
                    </div>
                </div>

                {/* <div className='arrow-intro-container'>
                    <div className='arrow-intro'>
                        <svg
                            version="1.1"
                            width="105"
                            height="115"
                            fill="none"
                            stroke="#03e9f4"
                            strokeWidth="2"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <path d="M 10,100 C 50,100 100,50 100,10" />

                            <path d="M92,20 L100,10" />

                            <path d="M101,10 L105,22" />

                            <path className="path" stroke="#70dcff" strokeMiterlimit="10" d="M 10,100 C 50,100 100,50 100,10" />
                        </svg>
                    </div>
                    <p>click me</p>
                </div> */}
            </>}
            {showPanel && <Panel imagesAwards={loadedImagesAwards} imagesBraca={loadedImagesBraca} imagesOthers={loadedImagesOthers} />}
            {/* <NatinaZavesa {...options} /> */}
        </>
    );
};

export default PanelIntro;
