import React from 'react';
import '../styles/about.scss';

const AboutMe = () => {
    return (
        <div className='about-container  h-dvh w-dvw'>
            <div className='h-dvh w-dvw overfloy-y-auto'>
                <header className="masthead">
                    <p data-translation-key="aboutZ" className="about--p">Hi, I'm</p>
                    <h1 className="masthead-heading">Srđan<a className="scroll-icon scroll-icon-container"></a></h1>
                </header>

                {/* the div is used to apply the gradient */}
                <div className="gradient">
                    {/* Body (SECTION 2/3) */}
                    <section>
                        <h2 data-translation-key="about0" className='about-h2'>Introduction</h2>
                        <p data-translation-key="about1" className='about-section-p'>
                            I'm Srđan Janković, a filmmaker born in Serbia, educated at Mount Mercy University (US) and the Met Film School Berlin (Germany), and currently based in Belgrade. I hold two bachelor’s degrees and work as a screenwriter and director.
                        </p>
                        <p data-translation-key="about2" className="space-maker about-section-p">
                            My work involves collaborations with esteemed production companies such as Telekom Serbia, E.T.M. Production, and MET Film School Production. Notable recent projects include the acclaimed TV series "Slozna Braća Next Đeneration" (Seasons 1 and 2), the gripping short film "ReaLife," the poignant documentary "Life on a String," and the award-winning short film " The True Story of ReaLife Crash."
                        </p>
                    </section>

                    <section>
                        <h2 data-translation-key="about3" className='about-h2'>What I did</h2>
                        <p data-translation-key="about4" className='about-section-p'>
                            I've been fortunate to receive recognition such as the Best Short Film Award at prestigious festivals like the Hollywood Independent Film Festival and the Vyre Film Festival and selected at Ealing Film Festival. Notably, "Složna Braća Next Djeneration" became the most-watched TV series in Bosnia and Herzegovina in 2022.
                        </p>
                        <p data-translation-key="about5" className="space-maker about-section-p">
                            Beyond my roles as a screenwriter and director, I've contributed as a 1st Assistant Director, Producer, Editor, and had the honor of serving on the "Young Jury" at the ARTE Film Festival in Paris, France.
                        </p>
                    </section>
                    {/* 
                <section>
                    <h2>More About Me</h2>
                    <h3>What are your favorite hobbies?</h3>
                    <p className="space-maker">
                        Te harum graeco suavitate sed, at dolore epicuri omittam usu. Porro
                        dicant recusabo et eum. Usu eu nihil munere urbanitas, eu vix unum
                        corpora partiendo, vis legere euismod utroque eu.
                    </p>
                    <h3>What's your dream job?</h3>
                    <p className="space-maker">
                        Amet possim ex mei, vis ei mucius aperiam, ea quidam adolescens
                        sed. Tota detracto cu duo, delenit argumentum eu quo.
                    </p>
                    <h3>What music have you been listening to lately?</h3>
                    <p>
                        Ius postulant petentium at, ancillae delicata interpretaris in
                        vis, ei eos suscipit nominati quaerendum. Quo ne tota ludus
                        vivendum. An usu noluisse delicata, delectus petentium et eum.
                    </p>
                </section> */}
                </div>
                <footer className="content-footer">

                </footer>
            </div>
        </div>
    );
};

export default AboutMe;
