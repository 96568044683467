import React, { useState, useEffect } from 'react';
import '../styles/video-list.scss';
import { isPhone, isTablet } from '../util/DeviceType';

const MovieCards = ({ movie, year, genre, description, trailerIds, onWatchTrailer }) => {
    return (
        <div className="movie-card" data-movie={movie}>

            <div className={`movie-card__overlay`}>
            </div>
            <div className="movie-card__share">
                {/* <button className="movie-card__icon">
                    <i className="material-icons fa-regular fa-heart"></i>
                </button>
                <button className="movie-card__icon">
                    <i className="material-icons fa-regular fa-message"></i>
                </button> */}
                <button className="movie-card__icon">
                    <i className="material-icons fa-solid fa-share-nodes"></i>
                </button>
            </div>
            <div className="movie-card__content">
                <div className="movie-card__header">
                    <h1 className="movie-card__title">{movie}</h1>
                    <h4 className="movie-card__info">({year}) {genre}</h4>
                </div>
                <p className="movie-card__desc">{description}</p>
            </div>
            <button className="movie-card__button" type="button" onClick={() => onWatchTrailer(trailerIds)}>
                <i className="fa-solid fa-play fa-fafaml"></i>Watch Trailer
            </button>
        </div>
    );
};

const MovieCard = ({ movie, year, genre, butt = 'Watch Trailer', description, trailerIds, onWatchTrailer, lang }) => {
    const [showElement, setShowElement] = useState(false);
    const [copyStatus, setCopyStatus] = useState('');
    const [movieLinkState, setMovieLinkState] = useState('');


    const [movieNameSt, setmovieNameSt] = useState('');
    const [movieButts, setmovieButts] = useState('');
    const [movieDesc, setmovieDesc] = useState('');

    const movieLinks = {
        "SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 1) TV SERIES": 'https://www.youtube.com/watch?v=pVdxfVTNA94&&ab_channel=SUPERSTARTV',
        "SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 2) TV SERIES": 'https://www.youtube.com/watch?v=O2O9JjcaV-0&&t=3s&&ab_channel=SUPERSTARTV',
        "Interview": 'https://vimeo.com/899892118',
        "NELE KARAJLIĆ i SRDJAN JANKOVIĆ - Nikola Radin Podcast": 'https://vimeo.com/899892118',
        "<The True Story of the ReaLife Crash>": 'https://youtu.be/kB3AcEjgaME',
        "Berlin Rising": 'https://www.youtube.com/watch?v=XWy88vY5rg4&&ab_channel=VincentJanssenProductions',
        "Life on a String": 'https://www.youtube.com/watch?v=4mNJRbZxp80&t=11s',
        "ReaLife": '',
    };

    const movieName = movie;

    const movieLink = movieLinks[movieName];
    useEffect(() => {
        setMovieLinkState(movieLink);
    }, [movieLink]);

    useEffect(() => {

        if (movie == "SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 1) TV SERIES") {
            setmovieNameSt(getTranslation('sloznaB1'));
            setmovieDesc(getTranslation('opis1'));
        }
        else if (movie == "SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 2) TV SERIES") {
            setmovieNameSt(getTranslation('sloznaB2'));
            setmovieDesc(getTranslation('opis2'));
        }
        else if (movie == "Interview") {
            setmovieNameSt(getTranslation('sloznaB3'));
            setmovieDesc(getTranslation('opis7'));
        }
        else if (movie == "NELE KARAJLIĆ i SRDJAN JANKOVIĆ - Nikola Radin Podcast") {
            setmovieNameSt(movie);
            setmovieDesc(getTranslation('opis8'));
        }
        else if (movie == "<The True Story of the ReaLife Crash>") {
            setmovieNameSt(movie);
            setmovieDesc(getTranslation('opis3'));
        }
        else if (movie == "Berlin Rising") {
            setmovieNameSt(movie);
            setmovieDesc(getTranslation('opis4'));
        }
        else if (movie == "Life on a String") {
            setmovieNameSt(movie);
            setmovieDesc(getTranslation('opis5'));
        }
        else if (movie == "ReaLife") {
            setmovieNameSt(movie);
            setmovieDesc(getTranslation('opis6'));
        }
        else {
            setmovieNameSt(movie);
            setmovieDesc(getTranslation('opis1'));
        }

        if (butt == "Watch Trailer") {
            setmovieButts(getTranslation('buts1'));
        }
        else if (butt == "Watch Interview") {
            setmovieButts(getTranslation('buts2'));
        }
        else if (butt == "Watch the Podcast") {
            setmovieButts(getTranslation('buts3'));
        }
        else if (butt == "Watch the Film") {
            setmovieButts(getTranslation('buts4'));
        } else {
            setmovieButts(butt);
        }

    }, [lang]);
    const getTranslation = (key) => {
        const translations = {
            en: {
                sloznaB1: 'SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 1) TV SERIES',
                sloznaB2: 'SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 2) TV SERIES',
                sloznaB3: 'Interview',
                buts1: 'Watch Trailer',
                buts2: 'Watch Interview',
                buts3: 'Watch the podcast',
                buts4: 'Watch the Film',
                opis1: 'Co-writer and 1st assistant director Srđan Janković </br>Production - Telekom Srbija, executive production - ETM Production.',
                opis2: 'Co-writer Srđan Janković </br>Production - Telekom Srbija, executive production - ETM Production.',
                opis3: 'Written and Directed by Srđan Janković </br>Production - MET Film School.',
                opis4: 'Writer, producer, assistant director Srđan Janković </br>Production - MET Film School + Fundraising.',
                opis5: 'Written and Directed by Srđan Janković, </br>Production Denis Leo Hegić and MET Film School Berlin',
                opis6: 'Written and Directed by Srđan Janković, </br>Production Nenad Janković PR.',
                opis7: 'Interview with Srđan Janković, </br>co-writer of Složna Braća',
                opis8: 'Interview with Srđan Janković, </br>co-writer of Složna Braća',
            },
            sr: {
                sloznaB1: 'Složna Braća Next Djeneration Serija',
                sloznaB2: 'Složna Braća Next Djeneration 2 Serija',
                sloznaB3: 'Intervju',
                buts1: 'Gledaj Trejler',
                buts2: 'Gledaj Intervju',
                buts3: 'Gledaj Podkast',
                buts4: 'Gledaj Film',
                opis1: 'Ko-scenarista i 1. asistent režije Srđan Janković </br>Produkcija - Telekom Srbija, Izvršna produkcija - ETM Production.',
                opis2: 'Ko-scenarista Srđan Janković </br>Produkcija - Telekom Srbija, Izvršna produkcija - ETM Production.',
                opis3: 'Režirao i napisao Srđan Janković </br>Produkcija - MET Film School.',
                opis4: 'Režirao i napisao Srđan Janković </br>Produkcija - MET Film School + Fundraising.',
                opis5: 'Režirao i napisao Srđan Janković, </br>Produkcija Denis Leo Hegić i MET Film School Berlin',
                opis6: 'Režirao i napisao Srđan Janković, </br>Produkcija Nenad Janković PR.',
                opis7: 'Intervju sa Srđanom Jankovićem, </br>Ko-scenarista Složne Braće',
                opis8: 'Intervju sa Srđanom Jankovićem, </br>Ko-scenarista Složne Braće',

            },
        };

        return translations[lang][key] || key; // Fallback to the key if translation is not found
    };
    const handleShareButtonCopy = () => {
        // Copy text to clipboard
        const textToCopy = movieLink; // Replace this with the text you want to copy
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopyStatus('Copied!');
            })
            .catch((err) => {
                setCopyStatus('Error copying text');
                console.error('Error copying text:', err);
            });
    };

    const handleShareButtonClick = () => {
        setShowElement(true);
        // Set a timeout to hide the element after 5 seconds
        setTimeout(() => {
            setShowElement(false);
            setCopyStatus('');
        }, 3000);
    };

    return (
        <div className="movie-card" data-movie={movie}>
            <div className={`movie-card__overlay`}>
            </div>
            <div className={`share-link-link`} style={{ display: showElement ? 'block' : 'none' }} onClick={handleShareButtonCopy}>
                <p>{movieLinkState}</p>
                <p>{copyStatus}</p>
            </div>
            <div className="movie-card__share">
                <button className="movie-card__icon" onClick={handleShareButtonClick}>
                    <i className="material-icons fa-solid fa-share-nodes"></i>
                </button>
            </div>
            {/* The rest of your component remains unchanged */}
            <div className="movie-card__content">
                <div className="movie-card__header">
                    <h1 className="movie-card__title">{movieNameSt}</h1>
                    <h4 className="movie-card__info">{year} {genre}</h4>
                </div>
                <p className="movie-card__desc" dangerouslySetInnerHTML={{ __html: movieDesc }}></p>
            </div>
            <button className="movie-card__button" type="button" onClick={() => { if (movie != 'ReaLife') onWatchTrailer(trailerIds) }}>
                {(movie != 'ReaLife') && <i className="fa-solid fa-play fa-fafaml"></i>}{movieButts}
            </button>
        </div>
    );
};


const VideoList = (props) => {
    const { whatToShow, lang } = props;
    const [showTrailer, setShowTrailer] = useState(false);
    const [currentTrailerIndex, setCurrentTrailerIndex] = useState(0);
    const [trailerIds, setTrailerIds] = useState([]);


    const [showTrailerBraca, setShowTrailerBraca] = useState(false);
    const [showTrailerIntervju, setShowTrailerIntervju] = useState(false);
    const [showTrailerOstalo, setShowTrailerOstalo] = useState(false);

    const [trailerHeight, settrailerHeight] = useState("550");
    const [trailerWidth, settrailerWidth] = useState("1000");

    useEffect(() => {

        if (isPhone()) {
            settrailerHeight("205");
            settrailerWidth("300");
        }
        if (isTablet()) {
            settrailerHeight("405");
            settrailerWidth("600");
        }

        let showTrailerBraca = false;
        let showTrailerIntervju = false;
        let showTrailerOstalo = false;

        switch (whatToShow) {
            case 'braca':
                showTrailerBraca = true;
                break;
            case 'intervju':
                showTrailerIntervju = true;
                break;
            case 'ostalo':
                showTrailerOstalo = true;
                break;
            default:
                break;
        }

        // Update state based on whatToShow
        setShowTrailerBraca(showTrailerBraca);
        setShowTrailerIntervju(showTrailerIntervju);
        setShowTrailerOstalo(showTrailerOstalo);
    }, [whatToShow]);


    const handleWatchTrailer = (newTrailerIds) => {
        setTrailerIds(newTrailerIds);
        setShowTrailer(!showTrailer);
    };

    const handleCloseTrailer = () => {
        setShowTrailer(false);
    };

    return (
        <div className='gull-cont h-dvh w-dvw'>
            {showTrailerBraca && (
                <div className=' h-dvh w-dvw' id="movie-card-list">
                    <MovieCard
                        lang={lang}
                        movie="SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 1) TV SERIES"
                        year="(2022)"
                        genre=""
                        description={(
                            <span>
                                Co-writer and 1st assistant director Srđan Janković <br />
                                Production - Telekom Srbija, executive production - ETM Production.
                            </span>
                        )}
                        trailerIds={['pVdxfVTNA94?si=1QQ5o2ibhwGEwnwY']}
                        onWatchTrailer={handleWatchTrailer}
                    />
                    <MovieCard
                        lang={lang}
                        movie="SLOŽNA BRAĆA NEXT ĐENERATION (SEASON 2) TV SERIES"
                        year="(2023)"
                        genre=""
                        description={(
                            <span>
                                Co-writer Srđan Janković<br />
                                Production - Telekom Srbija, executive production - ETM Production.
                            </span>
                        )}
                        trailerIds={['O2O9JjcaV-0?si=gLtQnNNMfA6nJII6']}
                        onWatchTrailer={handleWatchTrailer}
                    />

                    {showTrailer && (
                        <div className="trailer-element h-dvh w-dvw">
                            <button className='fa-solid fa-square-xmark fa-xl' onClick={handleCloseTrailer}></button>
                            <iframe
                                className="trailer-t"
                                width={trailerWidth}
                                height={trailerHeight}
                                src={`https://www.youtube.com/embed/${trailerIds[currentTrailerIndex]}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            />
                        </div>
                    )}

                </div>
            )}
            {showTrailerIntervju && (
                <div className='h-dvh w-dvw' id="movie-card-list">
                    <MovieCard
                        lang={lang}
                        movie="Interview"
                        year=""
                        genre=""
                        butt="Watch Interview"
                        description={(
                            <span >
                                Interview with Srđan Janković,<br />
                                co-writer of Složna Braća
                            </span>
                        )}
                        trailerIds={['player.vimeo.com/video/899892118?h=ace6d6c77d']}
                        onWatchTrailer={handleWatchTrailer}
                    />
                    <MovieCard
                        lang={lang}
                        movie="NELE KARAJLIĆ i SRDJAN JANKOVIĆ - Nikola Radin Podcast"
                        year=""
                        genre=""
                        butt="Watch the Podcast"
                        description={(
                            <span>
                                Interview with Srđan Janković,<br />
                                co-writer of Složna Braća
                            </span>
                        )}
                        trailerIds={['www.youtube.com/embed/MU_fdVmmgnI?si=Qly8P6q780zn2dG2']}
                        onWatchTrailer={handleWatchTrailer}
                    />

                    {/* <MovieCard
                        movie="Back to the Future 2"
                        year="1985"
                        genre="Adventure, Comedy, Sci-Fi"
                        description="Marty McFly, a 17-year-old high school student, is accidentally sent 30 years into the past in a time-traveling DeLorean invented by his close friend, the maverick scientist Doc Brown."
                        trailerIds={['O2O9JjcaV-0?si=gLtQnNNMfA6nJII6']}
                        onWatchTrailer={handleWatchTrailer}
                    /> */}

                    {showTrailer && (
                        <div className="trailer-element h-dvh w-dvw">
                            <button className='fa-solid fa-square-xmark fa-xl' onClick={handleCloseTrailer}></button>
                            <iframe className="trailer-t" src={`https://${trailerIds[currentTrailerIndex]}`}
                                width={trailerWidth}
                                height={trailerHeight} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    )}

                </div>
            )}
            {showTrailerOstalo && (
                <div className='h-dvh w-dvw overflow-auto' id="movie-card-list-en">
                    <div className='h-dvh w-dvw'>
                        <div className='container-www '>

                            <MovieCard
                                lang={lang}
                                movie="<The True Story of the ReaLife Crash>"
                                year="(2021)"
                                genre=""
                                butt="Watch the Film"
                                description={(
                                    <span>
                                        Written and Directed by Srđan Janković<br />
                                        Production - MET Film School.
                                    </span>
                                )} trailerIds={['kB3AcEjgaME?si=-f3FxngCw0K_hDGI']}
                                onWatchTrailer={handleWatchTrailer}
                            />
                            <MovieCard
                                lang={lang}
                                movie="Berlin Rising"
                                year="(2022)"
                                genre=""
                                description={(
                                    <span>
                                        Writer, producer and assistant director Srđan Janković<br />
                                        Production - MET Film School + Fundraising.
                                    </span>
                                )} trailerIds={['XWy88vY5rg4?si=hn1vwMhmNV0JsQxz']}
                                onWatchTrailer={handleWatchTrailer}
                            />
                            <MovieCard
                                lang={lang}
                                movie="Life on a String"
                                year="2024"
                                genre=""
                                description={(
                                    <span>
                                        Written and Directed by Srđan Janković,<br />
                                        Production Denis Leo Hegić and MET Film School Berlin
                                    </span>
                                )} trailerIds={['4mNJRbZxp80?si=A9l6w8SbAZ5MupHc']}
                                onWatchTrailer={handleWatchTrailer}
                            />
                            <MovieCard
                                lang={lang}
                                movie="ReaLife"
                                year="2024"
                                genre=""
                                butt='On the festival circuit!'
                                description={(
                                    <span>
                                        Written and Directed by Srđan Janković,<br />
                                        Production Nenad Janković PR.
                                    </span>
                                )} trailerIds={['XWy88vY5rg4?si=hn1vwMhmNV0JsQxz']}
                                onWatchTrailer={handleWatchTrailer}
                            />
                        </div>
                        {showTrailer && (
                            <div className="trailer-element  h-dvh w-dvw">
                                <button className='fa-solid fa-square-xmark fa-xl' onClick={handleCloseTrailer}></button>
                                <iframe
                                    className="trailer-t"
                                    width={trailerWidth}
                                    height={trailerHeight}
                                    src={`https://www.youtube.com/embed/${trailerIds[currentTrailerIndex]}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                />
                            </div>
                        )}

                    </div>

                    <div className='scroll-icon-container-video'>
                        <a className="scroll-icon"></a>
                    </div>

                    {/* <div className='w-100per' >
                        <div className='w-dvw container-www'>

                            <MovieCard
                                lang={lang}
                                movie="Life on a String"
                                year="2024"
                                genre=""
                                description={(
                                    <span>
                                        Written and Directed by Srđan Janković,<br />
                                        Production Denis Leo Hegić and MET Film School Berlin
                                    </span>
                                )} trailerIds={['4mNJRbZxp80?si=A9l6w8SbAZ5MupHc']}
                                onWatchTrailer={handleWatchTrailer}
                            />
                            <MovieCard
                                lang={lang}
                                movie="ReaLife"
                                year="2024"
                                genre=""
                                butt='On the festival circuit!'
                                description={(
                                    <span>
                                        Written and Directed by Srđan Janković,<br />
                                        Production Nenad Janković PR.
                                    </span>
                                )} trailerIds={['XWy88vY5rg4?si=hn1vwMhmNV0JsQxz']}
                                onWatchTrailer={handleWatchTrailer}
                            />
                        </div>
                        {showTrailer && (
                            <div className="trailer-element  h-dvh w-dvw">
                                <button className='fa-solid fa-square-xmark fa-xl' onClick={handleCloseTrailer}></button>
                                <iframe
                                    className="trailer-t"
                                    width={trailerWidth}
                                    height={trailerHeight}
                                    src={`https://www.youtube.com/embed/${trailerIds[currentTrailerIndex]}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                />
                            </div>
                        )}

                    </div> */}
                </div>
            )}


        </div>

    );
};

export default VideoList;
