
const devices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const DeviceType = {
  PHONE: "Phone",
  DESKTOP: "Desktop",
  LAPTOP: "Laptop",
};

export const DeviceOrientation = {
  PORTRAIT: "Portrait",
  LANDSCAPE: "Landscape",
};

export function getDeviceType() {
  if (devices.test(navigator.userAgent)) {
    // true for mobile device
    const isPortrait = window.innerHeight > window.innerWidth;
    return { deviceType: DeviceType.PHONE, deviceOrientation: isPortrait ? DeviceOrientation.PORTRAIT : DeviceOrientation.LANDSCAPE };
    // } else if (/Macintosh|Windows/.test(navigator.userAgent)) {
    //   // true for desktop/laptop device
    //   return { deviceType: DeviceType.DESKTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
  } else {
    const width = window.innerHeight > window.innerWidth;
    if (window.innerWidth > window.innerHeight) {
      const diagonalSize = Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2));
      if (diagonalSize < 19) {
        return { deviceType: DeviceType.DESKTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
      } else {
        const aspectRatio = window.innerWidth / window.innerHeight;
        if (aspectRatio > 1.5) {
          return { deviceType: DeviceType.DESKTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
        } else {
          return { deviceType: DeviceType.LAPTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
        }
      }
    } else {
      return { deviceType: DeviceType.PHONE, deviceOrientation: DeviceOrientation.PORTRAIT };
    }
  }
}

export function isDesktop() {
  return getDeviceType().deviceType == DeviceType.DESKTOP;
}

export function isPhone() {
  return getDeviceType().deviceType == DeviceType.PHONE;
}

export function isPortrait() {
  return getDeviceType().deviceOrientation == DeviceOrientation.PORTRAIT;
}

export function isLandscape() {
  return getDeviceType().deviceOrientation == DeviceOrientation.LANDSCAPE;
}

export function isLaptop() {
  return getDeviceType().deviceType == DeviceType.LAPTOP;
}

export function isTablet() {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  // Define width and height thresholds for tablets
  const tabletMinWidthThreshold = 768;
  const tabletMaxWidthThreshold = 1024;
  const tabletMinHeightThreshold = 600;
  const tabletMaxHeightThreshold = 1366;

  // Assuming a device is a tablet if both width and height are within the defined thresholds
  return (
    screenWidth >= tabletMinWidthThreshold &&
    screenWidth <= tabletMaxWidthThreshold &&
    screenHeight >= tabletMinHeightThreshold &&
    screenHeight <= tabletMaxHeightThreshold
  );
}

