import React, { useState } from 'react';
import '../styles/contact-form.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const [nameA, setname] = useState('');
    const [emailA, setemail] = useState('');
    const [messageA, setmessage] = useState('');

    const imdbsClick = () => {
        const imdbsLink = document.getElementById('imdbsLink');
        imdbsLink.click();
    }
    const instaClick = () => {
        const instaClick = document.getElementById('instaClick');
        instaClick.click();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Gather the form data
        const serviceId = 'service_i76aeu6';
        const templateId = 'template_y9y1r6f';
        const publicKey = '7oZC1vluKz9k3ZELJ';
        const templateParams = {
            from_name: nameA,
            from_email: emailA,
            to_name: 'Srđan Jankovic',
            message: messageA
        };

        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log("Email sent!");
                setname('');
                setemail('');
                setmessage('');
            })
            .catch((error) => {
                console.log("Error... sending email... ", error);
            });

    };


    return (
        <div className="contact-form-parent min-h-80 min-w-80 min-h-[80dvh]  min-w-[80dvw]">
            <section id="contact">
                {/* <h1 className="section-header">Contact</h1> */}
                <div className="contact-wrapper">
                    <form
                        id="contact-form"
                        className="form-horizontal"
                        role="form"
                        onSubmit={handleSubmit}
                    >
                        <div className="form-group">
                            <div className="col-sm-12">
                                <input
                                    type="text"
                                    className="form-control button-contact-des"
                                    id="name"
                                    placeholder="NAME"
                                    name="name"
                                    required
                                    value={nameA}
                                    onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-sm-12">
                                <input
                                    type="email"
                                    className="form-control button-contact-des"
                                    id="email"
                                    placeholder="EMAIL"
                                    name="email"
                                    required
                                    value={emailA}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                            </div>
                        </div>

                        <textarea
                            className="form-control button-contact-desc"
                            rows="8"
                            id="MESSAGEta"
                            placeholder="MESSAGE"
                            name="message"
                            required
                            value={messageA}
                            onChange={(e) => setmessage(e.target.value)}
                        ></textarea>

                        <button
                            className="send-button"
                            id="submit"
                            type="submit"
                            value="SEND"
                        >
                            <div className="alt-send-button">
                                <i className="fa fa-paper-plane send-text-plane"></i>
                                <span data-translation-key={`contact3`} className="send-text">SEND</span>
                            </div>
                        </button>
                    </form>

                    <div className="direct-contact-container">
                        <ul className="contact-list">
                            <li className="list-item">
                                <i className="fa-solid fa-location-dot fa-2x">
                                    <span id='contactLocation' className="contact-text place bgdds">Belgrade, Serbia</span>
                                </i>
                            </li>
                            {/* 
                            <li className="list-item">
                                <i className="fa fa-phone fa-2x">
                                    <span className="contact-text phone">
                                        <a href="tel:1-032-6000-000" title="Give us a call">
                                            (032) 6000-00
                                        </a>
                                    </span>
                                </i>
                            </li> */}

                            <li className="list-item">
                                <i className="fa fa-envelope fa-2x">
                                    <span className="contact-text gmail">
                                        <a className='gmail' href="mailto:#" title="Send us an email">
                                            jankovicbog@gmail.com
                                        </a>
                                    </span>
                                </i>
                            </li>
                        </ul>

                        <hr />


                        <ul className="social-media-list">
                            <li onClick={imdbsClick} className="imdbs">
                                <a id='imdbsLink' href="https://www.imdb.com/name/nm12596871/?ref_=nv_sr_srsg_0" target="_blank" rel="noopener noreferrer" className="contact-icon">
                                    <i className="fa-brands fa-imdb fa-2xl fa-opaopa" aria-hidden="true"></i>
                                </a>

                            </li>
                            <li onClick={instaClick} >
                                <a id='instaClick' href="https://www.instagram.com/gg_srdjan_jankovic/" target="_blank" className="contact-icon">
                                    <i className="fa-brands fa-instagram fa-opaopapapa" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>

                        <hr />

                        <div className="copyright" >&copy; ALL THE RIGHTS RESERVED <a className='natin-link' href="https://www.virsag.rs/" target="_blank" rel="noopener noreferrer"> virsag.rs</a></div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactForm;
