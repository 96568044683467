import React, { useState, useEffect } from 'react';
import '../styles/panel.scss'
import ContactForm from "./ContactForm";
import VideoList from "./VideoList";
import SlickGallery from "./SlickGallery";
import AboutMe from "./AboutMe";
import { isPhone } from '../util/DeviceType';


const Panel = ({ imagesAwards, imagesBraca, imagesOthers }) => {
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const [animKind, setAnimKind] = useState(0);
    const [language, setLanguage] = useState('en');
    const [isPhones, setisPhone] = useState(false);


    const handleLanguageChangeEn = () => {
        setLanguage('en');
    };

    const handleLanguageChangeSr = () => {
        setLanguage('sr');
    };


    const textPanel = {
        workSeries: 'Work / TV Series',
        contact: 'Contact',
        about: 'About',
        gallery: 'Gallery',
        awards: 'Awards',
        interviews: 'Interviews',
        movies: 'Work / Films',
        viewAll: isPhone() ? 'Go to the Center' : 'View All',
        goCenter: 'Go to the Center',
    };

    useEffect(() => {
        setPos();
    }, [posX, posY]);

    const hasClass = (el, cls) => el.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    const addClass = (el, cls) => {
        if (!hasClass(el, cls)) {
            el.className += ' ' + cls;
        }
    };

    const removeClass = (el, cls) => {
        if (hasClass(el, cls)) {
            const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    };

    const setPos = () => {
        const wrap = document.getElementsByClassName('panel-wrap')[0];
        wrap.style.transform = `translateX(${posX}00%) translateY(${posY}00%)`;
        setTimeout(() => {
            removeClass(wrap, 'animate');
        }, 600);
    };

    const move = (x, y) => {
        const wrap = document.getElementsByClassName('panel-wrap')[0];
        addClass(wrap, 'animate');
        setPosX(posX % 10 + x % 10);
        setPosY(posY % 10 + y % 10);
    };


    const moveUp = () => move(0, 1);
    const moveLeft = () => move(1, 0);
    const moveRight = () => move(-1, 0);
    const moveDown = () => move(0, -1);
    const moveDownLeft = () => move(1, -1);
    const moveDownRight = () => move(-1, -1);
    const moveUpLeft = () => move(1, 1);
    const moveUpRight = () => move(-1, 1);

    const toggleAnimation = (event) => {
        const clickedSpan = event.currentTarget;
        const animations = Array.from(document.getElementsByClassName('js-animation'));
        const wrap = document.getElementsByClassName('panel-wrap')[0];

        for (let x = 0; x < animations.length; x++) {
            if (clickedSpan === animations[x]) {
                addClass(clickedSpan, 'active');
                addClass(wrap, clickedSpan.getAttribute('data-animation'));
            } else {
                removeClass(animations[x], 'active');
                removeClass(wrap, animations[x].getAttribute('data-animation'));
            }
        }
    };

    const zoomOutTotal = (e) => {
        e.stopPropagation();
        const site = document.getElementsByClassName('site-wrap')[0];
        setPanelAndZoomOut();
        if (isPhone()) {
            addClass(site, 'show-all-mobile');
            removeClass(site, 'hide-all-mobile');
        } else {
            addClass(site, 'show-all');
        }
        const panels = document.getElementsByClassName('panel');
        for (let x = 0; x < panels.length; x++) {
            panels[x].addEventListener('click', setPanelAndZoom);
        }
    }

    const zoomOut = (e) => {

        if (isPhone()) {
            setPanelAndZoomOut();
        } else {


            e.stopPropagation();
            const site = document.getElementsByClassName('site-wrap')[0];
            setPanelAndZoomOut();
            // setTimeout(() => {
            if (isPhone()) {
                addClass(site, 'show-all-mobile');
                removeClass(site, 'hide-all-mobile');
            } else {
                addClass(site, 'show-all');
            }
            const panels = document.getElementsByClassName('panel');
            for (let x = 0; x < panels.length; x++) {
                panels[x].addEventListener('click', setPanelAndZoom);
            }
            // }, 50);
        }

    };

    /*
        const zoomOut = (e) => {
            e.stopPropagation();
            const site = document.getElementsByClassName('site-wrap')[0];
            addClass(site, 'show-all');
    
            const panels = document.getElementsByClassName('panel');
            for (let x = 0; x < panels.length; x++) {
                panels[x].addEventListener('click', setPanelAndZoom);
            }
    
            // Using requestAnimationFrame for smoother animation
            const animateZoomOut = () => {
                const currentScale = parseFloat(site.style.transform.replace(/[^0-9.-]/g, ''));
                if (currentScale > 0.33) {
                    site.style.transform = `rotateY(0deg) scale(${Math.max(0.33, currentScale - 0.005)})`;
                    requestAnimationFrame(animateZoomOut);
                } else {
                    setTimeout(() => {
                        setPanelAndZoomOut();
                    }, 650);
                }
            };
    
            animateZoomOut();
        };*/

    useEffect(() => {
        // Update text content based on the selected language
        const element1 = document.querySelector(`[data-translation-key="workTvSeries"]`)
        element1.textContent = getTranslation('workTvSeries');
        const element2 = document.querySelector(`[data-translation-key="contact"]`)
        element2.textContent = getTranslation('contact');
        const element3 = document.querySelector(`[data-translation-key="about"]`)
        element3.textContent = getTranslation('about');
        const element4 = document.querySelector(`[data-translation-key="gallery"]`)
        element4.textContent = getTranslation('gallery');
        const element5 = document.querySelector(`[data-translation-key="awards"]`)
        element5.textContent = getTranslation('awards');
        const element6 = document.querySelector(`[data-translation-key="interviews"]`)
        element6.textContent = getTranslation('interviews');
        const element7 = document.querySelector(`[data-translation-key="workMovies"]`)
        element7.textContent = getTranslation('workMovies');
        const element8 = document.querySelector(`[data-translation-key="gallery2"]`)
        element8.textContent = getTranslation('gallery');
        const element9 = document.querySelector(`[data-translation-key="viewAll"]`)
        element9.textContent = getTranslation('viewAll');
        const element10 = document.querySelectorAll(`[data-translation-key="viewAll2"]`)
        element10.textContent = getTranslation('viewAll2');
        if (element10.length > 0) {
            element10.forEach((element) => {
                element.textContent = getTranslation('viewAll2');
            });
        }

        //ABOUT
        const element11 = document.querySelector(`[data-translation-key="about0"]`)
        element11.textContent = getTranslation('about0');
        const element12 = document.querySelector(`[data-translation-key="about1"]`)
        element12.textContent = getTranslation('about1');
        const element13 = document.querySelector(`[data-translation-key="about2"]`)
        element13.textContent = getTranslation('about2');
        const element14 = document.querySelector(`[data-translation-key="about3"]`)
        element14.textContent = getTranslation('about3');
        const element15 = document.querySelector(`[data-translation-key="about4"]`)
        element15.textContent = getTranslation('about4');
        const element16 = document.querySelector(`[data-translation-key="about5"]`)
        element16.textContent = getTranslation('about5');
        const element17 = document.querySelector(`[data-translation-key="aboutZ"]`)
        element17.textContent = getTranslation('aboutZ');

        //Awards
        let totalIndex = 20; // Starting index value
        for (let index = 0; index < totalIndex; index++) {
            const element = document.querySelector(`[data-translation-key="awardsTitles${index}"]`);
            if (element) {
                element.textContent = getTranslation(`awardsTitles${index}`);
            } else {
                //console.warn(`Element with data-translation-key="awardsTitles${index}" not found`);
            }
        }

        //Braca 
        let totalIndexB = 20; // Starting index value
        for (let index = 0; index < totalIndexB; index++) {
            const element = document.querySelector(`[data-translation-key="behindTheScenes${index}"]`);
            if (element) {
                element.textContent = getTranslation(`behindTheScenes${index}`);
            } else {
                //console.warn(`Element with data-translation-key="awardsTitles${index}" not found`);
            }
        }

        //Other 
        let totalIndexO = 14; // Starting index value
        for (let index = 0; index < totalIndexO; index++) {
            const element = document.querySelector(`[data-translation-key="behindTheScenesO${index}"]`);
            if (element) {
                element.textContent = getTranslation(`behindTheScenes${index}`);
            } else {
                //console.warn(`Element with data-translation-key="awardsTitles${index}" not found`);
            }
        }

        //Video
        const element18 = document.querySelector(`[data-translation-key="interview"]`)
        if (element18) element18.textContent = getTranslation('interview');

        //Contact
        let nameContact = document.getElementById("name");
        if (nameContact) nameContact.placeholder = getTranslation(`contactFormName`);
        let messageContact = document.getElementById("MESSAGEta");
        if (messageContact) messageContact.placeholder = getTranslation(`contactFormMESSAGEta`);

        let contactLocation = document.getElementById("contactLocation");
        if (contactLocation) contactLocation.textContent = getTranslation(`contactLocation`);


    }, [language]);
    const getTranslation = (key) => {
        const translations = {
            en: {
                workTvSeries: 'Work / TV Series',
                contact: 'Contact',
                about: 'About',
                gallery: 'Gallery',
                awards: 'Awards',
                interviews: 'Interviews',
                workMovies: 'Work / Films',
                viewAll: 'View All',
                viewAll2: isPhone() ? 'Go to the center' : 'See All',
                aboutZ: "Hi, I'm",
                about0: 'Introduction',
                about1: 'I\'m Srđan Janković, a filmmaker born in Serbia, educated at Mount Mercy University (US) and Met Film School Berlin (Germany), and currently based in Belgrade. I hold two bachelor’s degrees and work as a screenwriter and director.',
                about2: 'My work involves collaborations with esteemed production companies such as Telekom Serbia, E.T.M. Production, and MET Film School Production. Notable recent projects include the acclaimed TV series "Složna Braća Next Đeneration" (Seasons 1 and 2), the gripping short film "ReaLife," the short documentary "Life on a String," and the award-winning short film "<The True Story of ReaLife Crash>."',
                about3: 'What I did',
                about4: 'I have been fortunate to receive recognition such as the Best Short Film Award at prestigious festivals like the Hollywood Independent Film Festival and the Vyre Film Festival and SELECTION at Ealing Film Festival. Notably, "Složna Braća Next Djeneration" became the most-watched TV series in Bosnia and Herzegovina in 2022.',
                about5: 'Beyond my roles as a screenwriter and director, I have also contributed as a 1st Assistant Director, Producer, and Editor. Additionally, I had the honor of serving on the \'Young Jury\' at the ARTE Film Festival in Paris, France.',
                awardsTitles0: 'WINNER',
                awardsTitles1: 'WINNER',
                awardsTitles2: 'WINNER',
                awardsTitles3: 'WINNER',
                awardsTitles4: 'WINNER',
                awardsTitles5: 'WINNER',
                awardsTitles6: 'HONORABLE Mention',
                awardsTitles7: 'HONORABLE Mention',
                awardsTitles8: 'HONORABLE Mention',
                awardsTitles9: 'SELECTION',
                awardsTitles10: 'SELECTION',
                awardsTitles11: 'SELECTION',
                awardsTitles12: 'SELECTION',
                awardsTitles13: 'SELECTION',
                awardsTitles14: 'SELECTION',
                awardsTitles15: 'SELECTION',
                awardsTitles16: 'SELECTION',
                awardsTitles17: 'SELECTION',
                awardsTitles18: 'SELECTION',
                awardsTitles19: 'SELECTION',
                behindTheScenes0: 'Behind the scenes',
                behindTheScenes1: 'Behind the scenes',
                behindTheScenes2: 'Behind the scenes',
                behindTheScenes3: 'Behind the scenes',
                behindTheScenes4: 'Behind the scenes',
                behindTheScenes5: 'Behind the scenes',
                behindTheScenes6: 'Behind the scenes',
                behindTheScenes7: 'Behind the scenes',
                behindTheScenes8: 'Behind the scenes',
                behindTheScenes9: 'Behind the scenes',
                behindTheScenes10: 'Behind the scenes',
                behindTheScenes11: 'Behind the scenes',
                behindTheScenes12: 'Behind the scenes',
                behindTheScenes13: 'Behind the scenes',
                behindTheScenes14: 'Behind the scenes',
                behindTheScenes15: 'Behind the scenes',
                behindTheScenes16: 'Behind the scenes',
                behindTheScenes17: 'Behind the scenes',
                behindTheScenes18: 'Behind the scenes',
                behindTheScenes19: 'Behind the scenes',
                behindTheScenes20: 'Behind the scenes',
                contact0: 'Name',
                contact1: 'Password',
                contact2: 'Message',
                contact3: 'Send',
                interview: `Interview with Srđan Janković, co-writer of Složna Braća`,
                contactFormName: `NAME`,
                contactFormMESSAGEta: `MESSAGE`,
                contactLocation: `Belgrade, Serbia`
            },
            sr: {
                workTvSeries: 'Projekti / TV Serije',
                contact: 'Kontakt',
                about: 'O meni',
                gallery: 'Galerija',
                awards: 'Nagrade',
                interviews: 'Intervjui',
                workMovies: 'Projekti / Filmovi',
                viewAll: 'Vidi sve',
                viewAll2: isPhone() ? 'Idi na centar' : 'Vidi sve',
                aboutZ: 'Zdravo, ja sam',
                about0: 'O meni',
                about1: 'Ja sam Srđan Janković, rođen u Srbiji, gde trenutno i živim. Završio sam Mount Mercy Univerzitet (SAD) sa diplomom iz marketinga i medija i komunikacije. Takođe sam i diplomirao na MET Film School Univerzitetu u Berlinu (Nemačka) kao filmski reditelj i scenarista.',
                about2: 'Projeki na kojima sam radio uključuju saradnje s priznatim produkcijskim kućama poput Telekom Srbija, E.T.M. Production i MET Film School Production. Neki od mojih značajnih nedavnih projekata su: TV serija "Složna Braća Next Đeneration" (Sezone 1 i 2), kratki igrani film "ReaLife", dokumentarni kratki film "Život na Struni" i visoko nagrađivani kratki film "<The True Story of the ReaLife Crash>".',
                about3: ' ',
                about4: 'Moj kratki film <The True Story of the ReaLife Crash> je do sada naišao na odlične kritike na prestižnim festivalima poput Hollywood Independent Film Festivala (nagrada za najbolju kratku komediju) i Vyre Film Festivala (Nagradu za najbolji internacionalni kratki film) kao i da bude selektovan na Ealing filmskom festivalu. Takođe, "Složna Braća Next Djeneration" postala je najgledanija TV serija u Bosni i Hercegovini za 2022. godinu. Osim uloga scenariste i reditelja, doprineo sam kao prvi pomoćnik reditelja, producent, montažer, i imao sam čast da budem deo "Young Jury" na ARTE Filmskom festivalu u Parizu 2022. godine.',
                about5: ' ',
                awardsTitles0: 'POBEDNIK',
                awardsTitles1: 'POBEDNIK',
                awardsTitles2: 'POBEDNIK',
                awardsTitles3: 'POBEDNIK',
                awardsTitles4: 'POBEDNIK',
                awardsTitles5: 'POBEDNIK',
                awardsTitles6: 'Posebno priznanje',
                awardsTitles7: 'Posebno priznanje',
                awardsTitles8: 'Posebno priznanje',
                awardsTitles9: 'Selekcija',
                awardsTitles10: 'Selekcija',
                awardsTitles11: 'Selekcija',
                awardsTitles12: 'Selekcija',
                awardsTitles13: 'Selekcija',
                awardsTitles14: 'Selekcija',
                awardsTitles15: 'Selekcija',
                awardsTitles16: 'Selekcija',
                awardsTitles17: 'Selekcija',
                awardsTitles18: 'Selekcija',
                awardsTitles19: 'Selekcija',
                behindTheScenes0: 'Slike sa seta',
                behindTheScenes1: 'Slike sa seta',
                behindTheScenes2: 'Slike sa seta',
                behindTheScenes3: 'Slike sa seta',
                behindTheScenes4: 'Slike sa seta',
                behindTheScenes5: 'Slike sa seta',
                behindTheScenes6: 'Slike sa seta',
                behindTheScenes7: 'Slike sa seta',
                behindTheScenes8: 'Slike sa seta',
                behindTheScenes9: 'Slike sa seta',
                behindTheScenes10: 'Slike sa seta',
                behindTheScenes11: 'Slike sa seta',
                behindTheScenes12: 'Slike sa seta',
                behindTheScenes13: 'Slike sa seta',
                behindTheScenes14: 'Slike sa seta',
                behindTheScenes15: 'Slike sa seta',
                behindTheScenes16: 'Slike sa seta',
                behindTheScenes17: 'Slike sa seta',
                behindTheScenes18: 'Slike sa seta',
                behindTheScenes19: 'Slike sa seta',
                behindTheScenes20: 'Slike sa seta',
                contact0: 'Ime',
                contact1: 'Šifra',
                contact2: 'Poruka',
                contact3: 'Pošalji',
                interview: `Intervju sa Srđanom Jankovićem, co-scenaristom Složne Braće`,
                contactFormName: `IME`,
                contactFormMESSAGEta: `PORUKA`,
                contactLocation: `Beograd, Srbija`
            },
        };

        return translations[language][key] || key; // Fallback to the key if translation is not found
    };


    const setPanelAndZoomOut = () => {
        setPosX(0);
        setPosY(0);
        setPos(); // Moved setPos here
    };

    const setPanelAndZoom = (e) => {
        setPosX(-e.target.getAttribute('data-x-pos'));
        setPosY(e.target.getAttribute('data-y-pos'));
        setPos(); // Moved setPos here
        zoomIn();
    };

    const zoomIn = () => {
        const panels = document.getElementsByClassName('panel');
        for (let x = 0; x < panels.length; x++) {
            panels[x].removeEventListener('click', setPanelAndZoom);
        }
        const site = document.getElementsByClassName('site-wrap')[0];
        if (isPhone()) {
            removeClass(site, 'show-all-mobile');
            addClass(site, 'hide-all-mobile');
        } else {
            removeClass(site, 'show-all');
        }
    };

    return (
        <div className="site-wrap animate h-dvh w-dvw">
            <div className="panel-wrap animate--none h-dvh w-dvw">
                <div className="panel h-dvh w-dvw" data-x-pos="0" data-y-pos="0">
                    <span data-translation-key="workTvSeries" className="panel__nav panel-nav-translated panel__nav--up js-up" onClick={moveUp}>Work / TV Series</span>
                    <span data-translation-key="contact" className="panel__nav panel-nav-translated panel__nav--right-top js-up js-right" onClick={moveUpRight}>Contact</span>
                    <span data-translation-key="about" className="panel__nav panel-nav-translated panel__nav--left-top js-up js-left " onClick={moveUpLeft}>About</span>
                    <span data-translation-key="gallery" className="panel__nav panel-nav-translated panel__nav--left js-left rotate90-m" onClick={moveLeft}>Gallery</span>
                    <span data-translation-key="gallery2" className="panel__nav panel-nav-translated panel__nav--right js-right rotate90" onClick={moveRight}>Gallery</span>
                    <span data-translation-key="awards" className="panel__nav panel-nav-translated panel__nav--right-down js-down js-right" onClick={moveDownRight}>Awards</span>
                    <span data-translation-key="interviews" className="panel__nav panel-nav-translated panel__nav--left-down js-down js-left" onClick={moveDownLeft}>Interviews</span>
                    <span data-translation-key="workMovies" className="panel__nav panel-nav-translated panel__nav--down js-down" onClick={moveDown}>Work / Films</span>

                    <div className='main-page-container'>
                        <div className='panel-h1-par'>

                            {/* <div className="language-picker" data-trigger-class="btn btn--subtle ">
                                <p className="language-btn" onClick={handleLanguageChangeSr}>SR</p>
                                <p className="language-c">/</p>
                                <p className="language-btn" onClick={handleLanguageChangeEn}>EN</p>
                            </div> */}

                            <span data-translation-key="viewAll" className="panel__zoom js-zoom" onClick={zoomOutTotal}>View All</span>
                            <h1 className='panel-h1 panel-h1-1'>
                            </h1>

                            <div className='neon-gg'>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <div className='neon-gg-ime'>Srđan</div>
                                <div>Janković</div>
                            </div>

                            <div className='panel-h1 panel-h1-2'> {/* Janković*/}
                                <div className="language-picker" data-trigger-class="btn btn--subtle ">
                                    <p className="language-btn" onClick={handleLanguageChangeSr}>SR</p>
                                    <p className="language-c">/</p>
                                    <p className="language-btn" onClick={handleLanguageChangeEn}>EN</p>
                                </div>
                            </div>
                            <div className="panel__animation-list">
                                <span className="js-animation active" data-animation="animate--none" onClick={toggleAnimation}>None</span>
                                <span className="js-animation" data-animation="animate--shrink" onClick={toggleAnimation}>Shrink</span>
                                <span className="js-animation" data-animation="animate--tilt" onClick={toggleAnimation}>Tilt</span>
                                <span className="js-animation" data-animation="animate--tilt2" onClick={toggleAnimation}>Tilt2</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="0" data-y-pos="1">
                    <VideoList whatToShow="braca" lang={language} />
                    <span data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up z-50" onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--left js-left" onClick={moveLeft}><i className="fa-solid fa-chevron-left fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right js-right" onClick={moveRight}><i className="fa-solid fa-chevron-right fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right-down js-down js-right panel__rotate-down-right" onClick={moveDownRight}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left-down js-down js-left panel__rotate-down-left" onClick={moveDownLeft}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--down js-down" onClick={moveDown}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="-1" data-y-pos="1">
                    <AboutMe />
                    <span id='seeAllAboutMe' data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up z-50 see-all-about-me" data-x-pos="-1" data-y-pos="1" onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--right-down js-down js-right panel__rotate-down-right" onClick={moveDownRight}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right js-right" onClick={moveRight}><i className="fa-solid fa-chevron-right fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--down js-down" onClick={moveDown}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    {/* <h1 className='panel-h1'>Up Left</h1> */}
                    <div>

                    </div>
                    {/* <h1 className='panel-h1'>About</h1> */}
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="1" data-y-pos="1">
                    <span data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up z-50" onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--left-down js-down js-left panel__rotate-down-left" onClick={moveDownLeft}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left js-left" onClick={moveLeft}><i className="fa-solid fa-chevron-left fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--down js-down" onClick={moveDown}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    {/* <h1 className='panel-h1'>Up Right</h1> */}
                    <ContactForm />
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="-1" data-y-pos="0">
                    <SlickGallery whatToShow="othersMovies" images={imagesOthers} />
                    <span data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up panel__nav--up-margin z-50" onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--up js-up" onClick={moveUp}><i className="fa-solid fa-chevron-up fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right-top js-up js-right panel__rotate-up-right" onClick={moveUpRight}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right js-right" onClick={moveRight}><i className="fa-solid fa-chevron-right fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right-down js-down js-right panel__rotate-down-right" onClick={moveDownRight}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--down js-down" onClick={moveDown}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    {/* <h1 className='panel-h1'>Gallery</h1> */}
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="-1" data-y-pos="-1">
                    <VideoList whatToShow="intervju" lang={language} />
                    <span data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up panel__nav--up-margin z-50" onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--up js-up" onClick={moveUp}><i className="fa-solid fa-chevron-up fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right-top js-up js-right panel__rotate-up-right" onClick={moveUpRight}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right js-right" onClick={moveRight}><i className="fa-solid fa-chevron-right fa-2xl"></i></span>
                    {/* <h1 className='panel-h1'>Interviews</h1> */}
                    {/* <ContactForm /> */}
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="1" data-y-pos="-1">
                    <SlickGallery whatToShow="awards" images={imagesAwards} />
                    <span data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up panel__nav--up-margin z-50" onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--up js-up" onClick={moveUp}><i className="fa-solid fa-chevron-up fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left-top js-up js-left panel__rotate-up-left" onClick={moveUpLeft}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left js-left" onClick={moveLeft}><i className="fa-solid fa-chevron-left fa-2xl"></i></span>
                    {/* <h1 className='panel-h1'>Down Right</h1> */}
                    {/* <h1 className='panel-h1'>Awards</h1> */}
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="1" data-y-pos="0">
                    <SlickGallery whatToShow="movieSloznaBraca" images={imagesBraca} />
                    <span data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up panel__nav--up-margin z-50" onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--up js-up" onClick={moveUp}><i className="fa-solid fa-chevron-up fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left-top js-up js-left panel__rotate-up-left" onClick={moveUpLeft}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left js-left" onClick={moveLeft}><i className="fa-solid fa-chevron-left fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left-down js-down js-left panel__rotate-down-left" onClick={moveDownLeft}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--down js-down" onClick={moveDown}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    {/* <h1 className='panel-h1'>Right</h1> */}
                </div>
                <div className="panel h-dvh w-dvw" data-x-pos="0" data-y-pos="-1">
                    <VideoList whatToShow="ostalo" lang={language} />
                    <span data-translation-key="viewAll2" className="panel__nav panel__nav--up js-up panel__nav--up-margin " onClick={zoomOut}>{isPhone() ? 'Go to the Center' : 'View All'}</span>
                    <span className="panel__nav panel__nav--up js-up" onClick={moveUp}><i className="fa-solid fa-chevron-up fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left-top js-up js-left panel__rotate-up-left" onClick={moveUpLeft}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right-top js-up js-right panel__rotate-up-right" onClick={moveUpRight}><i className="fa-solid fa-chevron-down fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--left js-left" onClick={moveLeft}><i className="fa-solid fa-chevron-left fa-2xl"></i></span>
                    <span className="panel__nav panel__nav--right js-right" onClick={moveRight}><i className="fa-solid fa-chevron-right fa-2xl"></i></span>
                    {/* <h1 className='panel-h1'>Down</h1> */}
                </div>
            </div>
        </div>
    );
};

export default Panel;



