import React, { useState, useEffect, useContext, useRef } from "react";
import { isPhone } from "../util/DeviceType";
import PanelIntro from "./PanelIntro";

import 'tailwindcss/tailwind.css'

export default function Home(props) {



  return (
    <div className="home h-dvh" id="home">
      <div className="home-h-dvh">
        <PanelIntro />
      </div>
    </div>
  );
}
