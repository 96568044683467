import React, { useState, useEffect } from 'react';
import '../styles/countdown.scss';


const Countdown = ({ triggerIntro }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [counter, setCounter] = useState(9);
    var intervalId;

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        const element = document.querySelector('.btn');
        if (isHovered) {
            if (element)
                element.style.transition = '0.5s';
            // element.style.boxShadow = 'inset 0 0 0 20px #03e9f4, 0 0 30px #03e9f4';
        } else {
            // if (element)
            //     element.style.boxShadow = 'inset 0 0 0 20px #fff, 0 0 30px #fff';
        }
    }, [isHovered]);

    // const addStyles = () => {

    //     const elements = document.querySelectorAll('.btn, .btn-info, .btn-h1, .btn-author, .btn-line');

    //     const element = document.querySelector('.countdown');
    //     element.style.transition = 'opacity 1s';
    //     element.style.opacity = '0';

    //     elements.forEach(element => {

    //         if (element.classList.contains('btn')) {
    //             element.classList.add('btn-clickanim')
    //         }

    //         if (element.classList.contains('btn-info')) {
    //             element.classList.add('btn-info-clickanim')
    //         }

    //         if (element.classList.contains('btn-h1')) {
    //             element.classList.add('btn-h1-clickanim')
    //         }

    //         if (element.classList.contains('btn-author')) {
    //             element.classList.add('btn-author-clickanim')
    //         }

    //         if (element.classList.contains('btn-line')) {
    //             element.classList.add('btn-line-clickanim')
    //         }

    //     });
    // };

    useEffect(() => {


        intervalId = setInterval(() => {
            setCounter(prevCounter => {
                const newCounter = prevCounter - 1;
                if (newCounter == 1) {
                    // triggerIntro(true);
                }
                if (newCounter <= 0) {
                    // document.querySelector('.countdown').style.opacity = '0';
                    // clearInterval(intervalId);
                }
                return newCounter < 0 ? null : newCounter;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='countdown' onClick={triggerIntro}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="screen"></div>
            <div className="lines"></div>
            {/* <div className="number">{counter}</div> */}
        </div>
    );
};

export default Countdown;
